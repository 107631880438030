import {appAxios} from "@/axios.js"
import {sleep} from '@/utils/sleep.js'
import {appType, appTypes} from '@/appType/appType.js'
import {initData} from '@telegram-apps/sdk'

export const app = {
  namespaced: true,

  state: () => ({
    appType,
    isOnline: true,
    isCrashed: false,
    isNewVersionAvailable: false,
    info: null,

    messageComponent: null,
    messageComponentData: null,

    [appTypes.TELEGRAM]: {
      launchParams: null,
    },
  }),

  getters: {},

  mutations: {
    setMessageComponent(state, data) {
      state.messageComponent = data?.messageComponent
      state.messageComponentData = data?.messageComponentData
    },
    setInfo(state, info) {
      state.info = info
    },
    setIsOnline(state, data) {
      state.isOnline = data
    },
    setIsCrashed(state, data) {
      state.isCrashed = data
    },
    setIsNewVersionAvailable(state, data) {
      state.isNewVersionAvailable = data
    },
    setTelegramLaunchParams(state, data) {
      state[appTypes.TELEGRAM].launchParams = data
    }
  },

  actions: {
    async init(context, payload) {
      console.log('appType', appType)

      if (appType === appTypes.TELEGRAM) {
        // if (appType === appTypes.WEB) {
        const launchParams = await telegramSDK.retrieveLaunchParams()
        // const launchParams = {
        // "initData": {
        //   "authDate": "2024-11-26T16:16:51.000Z",
        //   "chatInstance": "-9141442785431107266",
        //   "chatType": "sender",
        //   "hash": "659acdb80da7ff738fd7783ce290a21f73219eaf4c241dc2db4e0222ac6aa60a",
        //   "user": {
        //     "allowsWriteToPm": true,
        //     "firstName": "Timofey",
        //     "id": 639275338,
        //     "languageCode": "ru",
        //     "lastName": "Timofeev",
        //     "photoUrl": "https://t.me/i/userpic/320/2tVarXVPfPu27BST1NqxLBk3N7U9Y-fraT0YpL1LRUc.svg",
        //     "username": "ttimofeev"
        //   }
        // },
        // "initDataRaw": "user=%7B%22id%22%3A639275338%2C%22first_name%22%3A%22Timofey%22%2C%22last_name%22%3A%22Timofeev%22%2C%22username%22%3A%22ttimofeev%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2F2tVarXVPfPu27BST1NqxLBk3N7U9Y-fraT0YpL1LRUc.svg%22%7D&chat_instance=-9141442785431107266&chat_type=sender&auth_date=1732721636&signature=dmY6C5o8CKynA-U1RAQvsmd81GQslXqVq2fnQRcGfm3JkWB5pgbwJ9ocNM4xElGH1s5bbNM234k4yq917uSxDg&hash=289fc705e70c77ca45bf976ba046e7075c67533e24568a991d3f0e2a2114c631",
        // }
        context.commit('setTelegramLaunchParams', launchParams)

        // appAxios.
        //initDataRaw

        // const contactDate = await telegramSDK.requestContact()
        // const contactDate = {
        //   "contact": {
        //     "userId": 639275338,
        //     "phoneNumber": "+79960722023",
        //     "firstName": "Timofey",
        //     "lastName": "Timofeev"
        //   },
        //   "authDate": "2024-11-26T16:25:29.000Z",
        //   "hash": "7226c24f7bf069a368139ee9925c39d402f5e22b5487fba53d2629cae1135395"
        // }
      }

      await sleep(1e3)

      const res = await appAxios.guest.fetchDirectories()
      context.commit('setInfo', res.data)
    },
    async setMessageComponent(context, payload = {}) {
      const {messageComponent, messageComponentData} = payload ?? {}
      context.commit('setMessageComponent', {
        messageComponent,
        messageComponentData,
      })
    },
    async setOnlineState(context, payload) {
      context.commit('setIsOnline', payload)
    },
    async setCrashedState(context, payload) {
      context.commit('setIsCrashed', payload)
    },
    async setIsNewVersionAvailable(context, payload) {
      context.commit('setIsNewVersionAvailable', payload)
    },
  }
}
